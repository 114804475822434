/************** PROJECTS *****************/

.Projects{
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  margin-bottom: 5%;
  animation: fadeIn 3s forwards;
}


/************** KEYFRAMES *****************/

@keyframes fadeIn {
  from{
    opacity: 0;
  }
  to{
    opacity: 1;
  }
}

/************** RESPONSIVE MEDIA QUERIES *****************/

@media (max-width: 1000px){
  .Projects{
    display: flex;
    flex-direction: column;
    align-items: center;
    animation: fadeIn 3s forwards;
  }
}