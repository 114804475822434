/************** NAV BAR *****************/

nav {
  display: flex;
  margin: 10px;
  margin-right: 20px;
}

a{
  text-decoration: none;
  color: black;
}

a:hover{
  color: #b71e1e;
  text-decoration-line: underline;
}

.show {
margin-left: 10px;
}


/************** RESPONSIVE MEDIA QUERIES *****************/

@media (max-width: 768px){
  nav div:first-of-type {
    display: flex;
    flex-direction: column;
    margin: 10px;
  }
}