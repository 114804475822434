/************** CONTACT *****************/

.Contact{
  display: flex;
  margin: 10%;
}

/** Contact Image Section **/

.Contact-image{
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: fadeIn 3s forwards;
}

.Contact img{
  max-width: 70%;
  max-height: auto;
}

/** Contact Links Section **/

.Contact-links{
  display: flex;
  align-items: center;
  text-align: center;
  gap: 20px;
  animation: fadeIn 3s forwards;
}

.contact-link {
  font-size: 1.2rem;
}


/************** KEYFRAMES *****************/

@keyframes fadeIn {
  from{
    opacity: 0;
  }
  to{
    opacity: 1;
  }
}


/************** RESPONSIVE MEDIA QUERIES *****************/

@media (min-width: 280px) and (max-width: 320px) {
  .Contact {
    flex-direction: column;
    text-align: center;
  }

  .Contact img {
    max-width: 80%;
  }

  .Contact-links {
    font-size: .8em;
    margin-top: 10%;
    flex-direction: row;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .Contact {
    flex-direction: column;
    text-align: center;
  }

  .Contact img {
    max-width: 80%;
  }

  .Contact-links {
    font-size: 1em;
    margin-top: 10%;
    flex-direction: row;
  }
}

@media (min-width: 480px) and (max-width: 768px) {
  .Contact {
    flex-direction: column;
    text-align: center;
  }

  .Contact img {
    max-width: 80%;
  }

  .Contact-links {
    font-size: 1em;
    margin-top: 10%;
    flex-direction: row;
  }
}
