/************** PROJECT DETAILS *****************/

.ProjectDetail{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
}

.ProjectDetail p {
  font-size: 1rem;
}

/************** PROJECT DETAIL HEADER ELEMENT *****************/

.ProjectDetail-header {
  border: 2px solid black;
  border-radius: 6px;
  color: black;
  max-width: 60%;
  padding: 10px;
  margin: 20px;
  align-self: flex-start;
  margin-left: 5%;
}

.ProjectDetail-header:hover{
  transform: scale(1.02);
  box-shadow: 10px 10px 0 5px #b71e1e;
  transition: .5s ease-in-out;
}

.ProjectDetail-header h1 {
  font-size: 4em;
  text-align: center;
}

.ProjectDetail-header p{
  font-size: 1.2em;
}

.ProjectDetail-header p:first-of-type {
  text-align: justify;
  margin: 15px 40px;
}

.ProjectDetail-header-lists {
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 1rem;
}

.ProjectDetail-type {
  margin-right: 5%;
}

.ProjectDetail-stack {
  margin-right: 5%;
}

/************** PROJECT DETAIL HEADER IMG *****************/
.ProjectDetail-main-img {
  max-height: 30%;
  max-width: 80%;
  border-radius: 6px;
  align-self: center;
  margin-top: 5%;
  /* margin-left: 5%; */
  box-shadow: 0 5px 10px rgba(70, 70, 70, 0.3);
}


/************** PROJECT DETAIL IMAGE SECTION *****************/

.ProjectDetail-images {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5%;
}

.ProjectDetail-img {
  max-height: 30%;
  max-width: 60%;
  border-radius: 6px;
  margin: 10px;
  box-shadow: 0 5px 10px rgba(70, 70, 70, 0.3);
}

.ProjectDetail-img:first-of-type {
  margin-bottom: 40px;
}

/************** PROJECT DETAIL PURPOSE *****************/

.ProjectDetail-purpose {
  max-width: 80%;
  text-align: justify;
  font-size: 1.2em;
  margin-top: 10%;
}

.ProjectDetail-purpose  p{
  margin-top: 20px;
}


/************** PROJECT DETAIL VIDEO DEMO *****************/

.ProjectDetail-demo{
  margin-top: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ProjectDetail video {
  max-height: 30%;
  max-width: 90%;
  border-radius: 8px;
  padding: 20px;
}


/************** KEYFRAMES *****************/

@keyframes fade {
  from{
    opacity: 0;
  }
  to{
    opacity: 1;
  }
}

/************** RESPONSIVE MEDIA QUERIES *****************/


@media (min-width: 280px) and (max-width: 320px){

  .ProjectDetail-header p {
    font-size: .5em;
  }

  .ProjectDetail-header-lists p {
    font-size: .5em;
  }

  .ProjectDetail-header {
    max-width: 80%;
  }

  .ProjectDetail-header h1 {
    font-size: 2em;
  }

  .ProjectDetail-header p:first-of-type {
    margin: 5px;
  }

  .ProjectDetail-purpose p{
    font-size: .6em;
    max-width: 100%;
  }
}


@media (min-width: 320px) and (max-width: 768px){

  .ProjectDetail-header p {
    font-size: .2em;
  }

  .ProjectDetail-header-lists p {
    font-size: .4em;
  }

  .ProjectDetail-header {
    max-width: 90%;
  }

  .ProjectDetail-header p:first-of-type {
    margin: 10px;
  }

  .ProjectDetail-header h1 {
    font-size: 3em;
  }

  .ProjectDetail p {
    font-size: .8rem;
  }
}


@media (min-width: 768px) and (max-width: 1024px){

  .ProjectDetail-header {
    max-width: 80%;
  }

  .ProjectDetail-header h1 {
    font-size: 3em;
  }

  .ProjectDetail-header p {
    font-size: 1em;
  }

  .ProjectDetail-header p:first-of-type {
    margin: 10px;
  }
}

@media (min-width: 1024px) and (max-width: 1080px){

  .ProjectDetail-header p{
    font-size: 1em;
  }

  .ProjectDetail-header p:first-of-type {
    margin: 10px;
  }
}


