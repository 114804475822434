/************** QUESTION SELECT *****************/

.QuestionSelect {
  display: flex;
  border-radius: 6px;
  margin: 20px;
  padding: 20px;
  width: 100%;
  border: 2px solid black;
}

.QuestionSelect-buttons {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 50%;
}

.QuestionSelect-btn {
  background-color: transparent;
  border: 2px solid black;
  cursor: pointer;
  margin: 5px;
  padding: 10px;
  border-radius: 6px;
  color: black;

}

.QuestionSelect-text {
  width: 60%;
  margin-left: 20px;
  margin-right: 10px;
  font-size: 1rem;
  text-align: left;
}

.QuestionSelect-default {
  font-size: 1.5em;
  margin-top: 30px;
  text-align: center;
  margin-left: 30px;
}

.QuestionSelect-btn:hover {
  background-color: black;
  color: #fdfcf0;
}

.active {
  background-color: black;
  color: #fdfcf0;
}

/************** RESPONSIVE MEDIA QUERIES *****************/


@media (min-width: 280px) and (max-width: 320px) {
  .QuestionSelect-btn {
    font-size: .7rem;
  }

  .QuestionSelect-text {
    font-size: .9rem;
    width: 90%;
    margin-left: 10px;
    margin-top: 30px;
  }

  .QuestionSelect {
    width: 100%;
  }

  .QuestionSelect-buttons {
    flex-direction: column;
    width: 100%;
  }

  .QuestionSelect {
    flex-direction: column;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .QuestionSelect-btn {
    font-size: .85rem;
    padding: 9px;
  }

  .QuestionSelect-text {
    font-size: 1rem;
    width: 90%;
    margin-left: 10px;
    margin-top: 30px;
  }

  .QuestionSelect-buttons {
    flex-direction: column;
    width: 100%;
  }

  .QuestionSelect {
    flex-direction: column;
  }
}

@media (min-width: 480px) and (max-width: 768px) {
  .QuestionSelect-btn {
    font-size: 1rem;
    padding: 9px;
  }

  .QuestionSelect-text {
    font-size: 1rem;
    width: 90%;
    margin-top: 30px;
    margin-left: 10px;
  }

  .QuestionSelect-buttons {
    flex-direction: row;
    width: 100%;
  }

  .QuestionSelect {
    flex-direction: column;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .QuestionSelect-btn {
    font-size: 1rem;
  }

  .QuestionSelect-text {
    font-size: 1rem;
    margin-left: 8%;
  }

  .QuestionSelect-buttons {
    width: 50%;
  }
}